<template>
    
    <div>
        
        <b-row>
            <b-col v-for="group in groups" md="4" lg="4" cols="12">
                <b-card no-body class="ml-0 mr-0">
                    <b-card-header class="pb-1">
                        <b-card-title >{{ group.name }}</b-card-title>
                    </b-card-header>
                    <b-list-group flush class="mt-0">
                        <b-list-group-item v-for="module in getGroupModules( group.id )" class="d-flex justify-content-between">
                            <div>{{ module.name }}</div>
                            <b-link @click="remove(group, module)" v-b-tooltip.hover title="Удалить">
                                <feather-icon icon="XIcon" class="text-danger" />
                            </b-link>
                        </b-list-group-item>
                        <b-list-group-item v-for="module in getOtherModules( group.id )" class="d-flex justify-content-between">
                            <div>{{ module.name }}</div>
                            <b-link @click="add(group, module)" v-b-tooltip.hover title="Добавить">
                                <feather-icon icon="PlusIcon" class="text-success" />
                            </b-link>
                        </b-list-group-item>
                    </b-list-group>
                    
                </b-card>
            </b-col>
        </b-row>
        
    </div>
    
<!--        <b-card no-body>

            <b-card-header>
                <b-card-title>
                    Настройки прав групп
                </b-card-title>
            </b-card-header>

            <b-table-simple>
                <b-thead>
                    <b-tr>
                        <b-td colspan="5">
                             Пользователи
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>Модуль</b-td>
                        <b-td>Модуль</b-td>
                        <b-td>Модуль</b-td>
                        <b-td>Модуль</b-td>
                    </b-tr>
                </b-thead>
            </b-table-simple>

        </b-card>-->
    
</template>

<script>

    import App from "@/modules/contents/app"

    export default {

        data() {
            return {
                App
            }
        },
        methods: {
            
            add( group, module ) {
                this.$request.post("modules/add", {
                    module: module.id,
                    group: group.id
                }).then( result => {
                    
                    this.App.groups.perms.push({
                        group: group,
                        registry: module
                    });
                    
                    this.$forceUpdate();
                    
                } );
            },
            
            remove( group, module ) {
                this.$request.post("modules/delete", {
                    module: module.id,
                    group: group.id
                }).then( result => {
                    
                    this.App.groups.perms.forEach((item,index) => {
                        if( item.group.id === group.id && item.registry.id === module.id ) {
                            this.App.groups.perms.splice(index, 1);
                        }
                    });
                    
                    this.$forceUpdate();
                    
                });
            },
            
            getGroupModules( group ) {
                return this.App.groups.perms.filter( item => parseInt(item.group.id) === parseInt(group) ).map( item => item.registry );
            },
            getOtherModules( group ) {
                var active = this.getGroupModules(group).map(item => item.id);
                return this.App.modules.filter( item => active.indexOf(item.id) < 0 );
            },
        },
        components: {

        },
        computed: {
            groups() {
                return App.groups.list
            },
        },
        watch: {

        },
        mounted() {

        }

    }

</script>